.column.results-list.item-main {
    flex: 1;
}
    
.column.results-list.item-side {
    flex: 0 0 8rem;
}
 
.ui.grid {
    margin: 0px;
    & > .row > .column {
        position: relative;
        display: inline-block;
        width: 6.25%;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        vertical-align: top;
    }
    & > .detail-main-info {
        margin-top: -7rem;
        margin-bottom: 3rem;
        border-radius: 25px;
        box-shadow: 0 -0.5em .4em rgba(255, 255, 255, 0.55);
        background-color: white;
        padding: 2rem 0 2rem 1rem !important;

        i{
            color: @carrotOrange;
        }
    }
    & > .row {
        &.detail-search-menu {
            margin: 0.5rem;
        }

        & > .column.main-record-content {
            @media screen and (min-width: @tabletBreakpoint) and (max-width: 1300px) {
                margin-bottom: 1rem;
            }
        }

        & > .column.detail-back-to-search-link {
            display: flex;
            align-items: center;

            a {
                &:hover{
                    color: @carrotOrange;
                }
                color: @yaleBlue;
            }



            .left.chevron.icon {
                display: inline-block;
                margin-right: 40px;
            }
        }

        &.result-options {
            padding-bottom: 0;
        }

        &.facets-and-search-listing {
            padding-top: 0.5rem;
        }
    }
}