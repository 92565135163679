.ui.message {
  &.form-feedback.orange {
    .content:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    color: black;
    .header {
      color: black;
    }
  }
}
