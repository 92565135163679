.ui.horizontal.list.inline {
    display: inline;
}

.ui.link.items.search-listing-item-group {
    .search-listing-item {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .row {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.ui.list.keywords {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    margin-top: 1rem;

    & > .item {
        margin-left: 0;
        color: @carrotOrange;
    }
}

.ui.list .list > .item > i.icon, .ui.list > .item > i.icon {
    color: @oxfordBlue;
}

.ui.list {
    > .item .description {
        padding-top: 1rem;
    }
}