.ui.list {
  .ui.tree-field &.tree-column {
    margin: 0;
    width: 100%;
    overflow-y: auto;
    min-width: 250px;

    &:not(:first-child) {
        border-left: 1px solid @borderColor;
    }

    &:not(:has(.item)) {
      display: none;
    }

    .item.spaced {
      border-bottom: 1px solid @borderColor;
      padding: 0.5rem;
      min-height: 3.5rem;
      display: flex;
      cursor: pointer;

      & :hover {
        text-decoration: underline;
      }

      & :not(:first-child) {
        border-top: 0;
      }

      .content {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .checkbox {
        padding-right: 1.5rem;
      }
      &.open {
        background-color: @treeSelectedColor;
      }
    }
  }
}
