.ui.action.input.search-input {

  background: white;
  border: 1px solid @carrotOrange;
  border-right: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 25px !important;
  box-shadow: 0em 0em 0em 0em transparent inset;

  &:hover{
    border: 1px solid @carrotOrangeDarker;
  }

  & > input {
    font-weight: 700;
    border: none;
    border-radius: 25px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ui.button.search {

    &:hover{
        background-color: @carrotOrangeDarker;
    }

    border-radius: 25px;
    background-color: @carrotOrange;
    color: @oxfordBlue;
    border: 1px solid @carrotOrange;
  }
}

.ui.action.input.search-input.title-page{
    width: 30%;
}